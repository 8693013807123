.container-kindcommerce {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card-circle {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 50px;
  align-items: center;
  width: auto;
}
