.container-btn-ad {
  display: flex;
  justify-content: center;
}
.btn-ad-left {
  text-align: start;
  padding: 2px;
}
.btn-ad-center {
  text-align: center;
  padding: 2px;
}
.btn-ad-rigth {
  text-align: end;
  padding: 2px;
}
.goder-body {
  margin-top: 10px;
  margin-inline: 17px;
  margin-bottom: 17px;
}
.goder-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
}

.goder-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.goder-img-list {
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 275px;
}

.goder-link-list {
  text-decoration: none;
  color: #094683;
  font-size: large;
  font-weight: 300;
  text-transform: uppercase;
}