@media screen and (min-width: 770px) {
  .Timeline-ColLeft {
    padding-top: 100px;

    padding-right: 10px;
    position: absolute;
    overflow: auto;
    overflow-x: auto;
    overflow-x: hidden;
    height: calc(100vh);
    transition: 0.3s margin-right ease-in;
    width: 100%;
  }
}
@media screen and (max-width: 769px) {
  .Timeline-ColLeft {
    padding-top: 70px;
  }
  .container-goder-tab {
    display: none;
  }
  .goder-button button {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
  }
}
/*
    margin-right: 80px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 80px;
    height: calc(100vh );
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: 0.3s margin-right ease-in;
    -moz-transition: 0.3s margin-right ease-in;
    -o-transition: 0.3s margin-right ease-in;
    -ms-transition: 0.3s margin-right ease-in;
    transition: 0.3s margin-right ease-in;
    overflow: auto;
    overflow-x: auto;
    
    overflow-x: hidden;
    */
