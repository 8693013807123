.container-sucessfull {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;

  color: #6f7c82;
  font-style: normal;
  overflow-x: hidden;
  padding: 0;
  justify-content: center;
  min-height: calc(100vh - 65px);
}

section.successfull {
  padding-top: 20px;
}
section.successfull .icon {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-color: rgba(62, 164, 235, 0.18);
  display: inline-block;
  margin-bottom: 20px;
}
section.successfull span.h1 {
  margin-top: 30px;
  margin-bottom: 10px;
}

section.successfull h1 {
  margin: 0;
  font-weight: 400;
}

section.successfull .done {
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-filter: alpha(opacity=0);
  -moz-filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -o-filter: alpha(opacity=0);
  filter: alpha(opacity=0);
  pointer-events: none;
}

section.successfull span.h1,
section.successfull span.p {
  position: relative;
  width: 100%;
}

/*
font-size: 62.5%; background-size: 100% 900px;  
*/
