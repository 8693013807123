.container-blogcarousel {
  padding-top: 10px;
  padding-bottom: 30px;
  background-image: url("https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/banner_calvario.jpg");
  /* background-color: #cccccc; */
   
}

.container-blogcarousel-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.blogcarousel-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 35px;
}

.blogcarousel-item .card {
  height: 200px;
  width: 350px;
}
.card .blogcaroulsel-avatar {
  left: -50px;
  top: 245px;
  position: absolute;
}
.rounded-circle {
  border-radius: 50% !important;
  height: 100px;
}

.blogcarousel-item .card .card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: initial;
  left: 50px;
  position: relative;
  top: 22px;
  font-size: 18px;
}

.blogcarousel-item .card .card-body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  text-align: initial;
  overflow: hidden;
}
.blogcarousel-item .card.avatar span {
  font-family: var(--font-primary);
  font-size: 16px;
  color: white;
  font-weight: 400;
}
.blogcarousel-item .card .avatar.medium span {
  font-size: 40px;
  color: #1db7e2;
}
.blogcarousel-item .card .avatar.big span {
  font-size: 30px;
}
.blogcarousel-item .card .avatar {
  left: -50px;
  top: 145px;
  position: absolute;
}

.blogcarousel-item .card .medium {
  width: 100px;
  height: 100px;
}
