@media screen and (min-width: 770px) {
  .Timeline-ColRight {
    padding-top: 100px;

    float: right;
    position: absolute;
    z-index: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh);
    transition: 0.3s transform ease-in;
    width: 100%;
    right: 0;
  }
  .container-goder-button {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .container-goder-button {
    display: block;
    text-align: center;
  }
  .container-goder-button button {
    margin: 5px;
  }
  .report-goder-container {
    display: none;
  }
}
/*
    bottom: 0;
    
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    -ms-perspective: 1000px;
    perspective: 1000px;
    height: calc(100vh );
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    
    
    top: 80px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: 0.3s -webkit-transform ease-in;
    -moz-transition: 0.3s -moz-transform ease-in;
    -o-transition: 0.3s -o-transform ease-in;
    -ms-transition: 0.3s -ms-transform ease-in;
    
   
    
    background: 
    #f6f6f6;
    padding-bottom: 1rem;
    */
