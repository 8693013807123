.goder-left {
}

.goder-right {
}
/*
 max-width: 800px ;
    width: 400px ;
    padding-left: 100px;
 max-width: 400px ;
    width: 300px ;
    padding-right: 100px;
*/
.container-goder {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}
