.container_about {
    padding-inline: 250px;
    background: white
}

.container_about_title {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 40px;
    padding-inline: 250px;
}

.img_about{
    min-height: 200px;
     height: 600px;
      width: 700px; 
      position: relative; 
      top: -70px; 
      left: 80px;
}
.icon_about{
    height: 70px;
     width: 70px; 
     margin-right: 30px;
    transition: width .5s; 
    transition: height .5s;
}

.icon_about:hover {
    width: 80px;
    height: 80px;
}

 @media screen and (max-width: 1300px) {

    .container_about {
        padding-inline: 60px;
    }
    .container_about_title {
        padding-inline: 200px;

    }
    .img_about {
        display: none;
    }

}

@media screen and (max-width: 800px) {

    .container_about {
        padding-inline: 20px;
    }
    .container_about_title {
        padding-inline: 100px;
        
    }
    

}

@media screen and (max-width: 500px) {

.container_about_title {
        padding-inline: 0px;

    }
   
} 