@media all and (min-width: 480px) {
  .Summary {
    padding: 70px 0;
    display: flex;
    justify-content: space-around;
  }
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.avatarBig {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
