/*@media screen and (min-width: 768px)*/
section.form h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
}
h3 {
  color: #292e31;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 0.2em;
}
section .icon {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-color: rgba(62, 164, 235, 0.18);
  display: inline-block;
  margin-bottom: 20px;
}
section.form .rating-control {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;

  width: 100%;

  padding-bottom: 9px;
}
section.form h4 {
  font-size: 1em;
  font-weight: 400;
  color: #313b3f;
  margin-top: 60px;

  line-height: 1.4em;
}
section.form .form-container {
  width: 100%;
  max-width: 400px;
  align-items: center;
  display: inline-block;
}
section.form .form-controls {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: center;
}

section.form .form-control {
  margin: 20px;
}

.rating-option {
  padding: 5px;
}
