@media all and (min-width: 200px) {
  .container-recovery-password {
    padding: 160px 0;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .container-recovery-password form {
    margin: 0 auto;
    max-width: 320px;
  }

  .container-recovery-password button {
    margin: 0 auto;
    width: 320px;
  }
  .container-recovery-password .title-recovery {
    text-align: center;
  }
  .container-recovery-password .addition-recovery {
    text-align: center;
    padding: 30px;
  }
}
