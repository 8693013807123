.field {
  padding: 10px;
  background-color: gainsboro;
  height: 45px;
}

.customer_btn {
  margin-top: 30px;
}

.customer_input {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
}
