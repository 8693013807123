.container-galery-one {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close {
  color: #f5f5f5;
  top: 100px;
  position: absolute;
  right: 0;
}
/*
background-color: black;
*/
