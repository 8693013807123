.container-onepay {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 65px);
  padding-top: 70px;
}
.section-information {
}
.section-information-customer {
}
.section-information-credicart {
}
.section-btn-pay {
  text-align: center;
  padding: 30px;
}
.information-aditional {
  padding-left: 40px;
}
