.slideHome {
  width: 100%;
  height: 350px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.avatar_store {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
  text-align: center;
}

.card.hovercard .avatar_store img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.store-left {
  position: sticky;
  top: 70px;
  padding-top: 10px;
}

.store-right {
  position: sticky;
  top: 70px;
  padding-top: 10px;
}
.store-center {
  margin-top: 10px;
}
.store-slider {
  padding-top: 45px;
  width: 100%;
}
.title-store {
  position: relative;
  display: flex;
  justify-content: center;
  color: #ffffff;
  top: -250px;
  background: transparent;
  font-size: 22px;
}

.description-store {
  position: relative;
  display: flex;
  justify-content: center;
  color: #ffffff;
  top: -220px;
  background: transparent;
  font-size: 22px;
}
.marketstore-right {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
