.container-chat {
  /*Contenedor del Chat en general*/
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 70px);
}

.card_chat {
  /*subcontenedor del Chat en general*/
  /*height: 500px; margin-bottom: 100px;*/
  border-radius: 15px !important;
  background-color: #fff !important;
  height: calc(100vh - 80px);

  padding: 10px;

  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header-chat {
  /*Cabecera del Chat en general*/
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
  padding: 8px;
  /*position: fixed;
  height: 250px;*/
  background: darkgray;
  z-index: 1;

  display: inline-flex;
  align-items: center;
}
.msg_card_body {
  /*cuerpo  de los mensajes en general*/
  overflow-y: auto;
  height: inherit;
}
.card-footer-chat {
  /*Piecera del Chat en general*/
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
  /* bottom: 10px;
  position: fixed;*/
  width: 100%;
}

.type_msg {
  /*textarea del Chat en general*/
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
  font-weight: bold;
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.attach_btn {
  /*boton adjuntar del Chat en general*/
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.attach_file {
  display: none;
}

.send_btn {
  /*boton enviar del Chat en general*/
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.active {
}
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}

.user_info {
  padding: 10px;
  font-weight: bold;

}
.user_info small {
  display: block;
  text-decoration: none;
  font-size: 12px;
  color: #4e4e50;
}
.user_info a {
  text-decoration: none;
  color: #fff;
}

.img_cont {
  position: relative;
  height: 72px;
  width: 72px;
  border: darkgrey;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: xx-large;
  color: #495057
}

.img_cont_msg {
  height: 40px;
  width: 40px;
}

/*En la mensajeria*/
.msg_cotainer {
  /*para los mensajes que recibo*/
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0px;
  margin-right: 10px;
  border-radius: 25px 25px 25px 0;
  background-color: #1db7e2; /*#82ccdd;*/
  padding: 10px;
  position: relative;
  min-width: 100px;
}
.msg_cotainer_send {
  /*para los mensajes que envio*/
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0px;
  margin-left: 10px;
  border-radius: 25px 25px 0 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
  min-width: 100px;
}
.msg_time {
  /*para la hora mensajes que recibo*/
  position: absolute;
  left: 0;
  bottom: -15px;
  color: #bdbbbb;
  font-size: 10px;
}
.msg_time_send {
  /*para la hora mensajes que envio*/
  position: absolute;
  right: 0;
  bottom: -15px;
  color: #bdbbbb;
  font-size: 10px;
}

.msg_date {
  /*para la fecha mensajes que envio*/
  color: #958f8f;
  font-size: 12px;
  justify-content: center;
  display: flex;
}

.msg_head {
  margin: 5px;
  position: relative;
  align-items: center;
  display: flex;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;

  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.msg_scroll {
  height: 0px;
}
.online_icon {
  position: absolute;
  height: 18px;
  width: 18px;
  /* background-color: #4cd137; */
  background-color: #1db7e2;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  border: 3px solid white
}
.offline {
  /* background-color: #c23616 !important;*/
}
.contact_name {
  margin-left: 15px;
  width: -moz-available;
  text-decoration: none;
  align-items: center;
    display: flex;
}
.contact_name span {
  font-size: 15px;
  color: #5b5c5d;
  font-weight: 600;
}
.contact_name small {
  display: block;
  text-decoration: none;
  font-size: 12px;
  color: #4e4e50;
}
.contact_name p {
  font-size: 10px;
  color: #4e4e50;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.search {
  border-radius: 15px 0 0 15px !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}

/*
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 0px !important;
  border-bottom: 1px dotted #b3a9a9;
}
*/
