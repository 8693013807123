.container-pricing {
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 65px);
  flex-direction: column;
}
.pricing-header {
  text-align: center;
}
