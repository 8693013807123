.answer {
  background: white;
  flex-direction: row;
  display: flex;
}
.goder-faq {
  padding-bottom: 100px;
}
.faq-date {
  display: inline-flex;
  padding-top: 5px;
  padding-right: 10px;
  color: #929292;
}
.question {
  display: flex;
  justify-content: space-between;
}
