.Home-out {
  margin: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}
.Home-in {
  margin: 10px;
  padding-top: 90px;
  display: flex;
  flex-direction: row;
}
.Home__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Home-left {
  position: sticky;
  top: 0;
}
@media screen and (min-width: 768px) {
  .Home__col {
    align-items: flex-start;
  }
}

/*
background: url('../../images/stars.svg'), #1B1B25;
  background-repeat: repeat;

  color: #ffffff;

  display: flex;
 min-height: calc(100vh - 60px);
  align-items: center;
*/
