.select {
    position: relative;
    word-wrap: normal;
   /** display: inline-block;**/
}

.select ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 45px;
    left: 0px;
    width: 200px;
    background-color: #fff;
    font-weight: bold;
    position: absolute;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.select li {
    padding: 8px 16px;
    border-bottom: 1px solid #6c6b6b;
}

.select a li {
    color: #495057;;
    text-decoration: none;
}
/*
.select li:last-child {
    border-bottom: none;
}

.select li:hover {
    background-color: #e5e5e5;
    color: #596377;
}
*/
.select .button {
    display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
       /** color: #495057;**/
        color:#6c757d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/*
.select .button:hover {
    color: rgb(199, 201, 202);
}
*/
.select .button:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border: 5px solid;
    border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
    right: 3px;
    top: 20px;
}

.select .nav-link {
    color: #596377;
    padding: 0.1rem 1rem;
}

.chevron-down {

    position: relative;
    display: inline;;
    transform: scale(var(--ggs, 1));
   
}
.widther{
    display: inline-flex;
    width: 60%;
}

.chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px
}