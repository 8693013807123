.footer {
  background-color: #343a40 !important;
  padding-bottom: 0.25rem !important;
  width: 100%;
}
.footer a {
  text-decoration: none;
  color: #fff;
}
.footer button {
  text-decoration: none;
}
.footer_right {
  color: #ffffff;
  font: bold;
  text-align: center;
}
.footer_icon {
  background-color: #0dbadf;
  color: #fff;
  font-size: 10px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  margin: 10px 15px 10px 0;
  padding: 8px;
}
.footer .footer_info {
  color: #0dbadf;
  text-decoration: none;
  font: bold 16px sans-serif;
  top: 20px;
  position: relative;
}
