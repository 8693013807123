@media all and (min-width: 480px) {
    .Screen {
        padding: 160px 0;
  
    }
  
    .Screen form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .Screen  button {
        margin: 0 auto;
        width:  auto;
      }
  }