.menu-mobile {
  position: fixed;
  background-color: #343a40;
  z-index: 3;
  top: 65px;
  left: 0;
  width: 50%;
  height: 100vh;
  display: flex;
  transition: 0.3s;
}

.menu-mobile.is-active {
  width: 0;
}
.menu-mobile ul {
  padding: 10px;
}

.menu-mobile ul li {
  color: #f5f5f5;
}
.menu-mobile ul li a {
  text-decoration: none;
  color: #f5f5f5;
  cursor: pointer;
}
