.dropdown_notification {
  position: relative;
  display: inline-block;
  top: -5px;
}
.dropdown_notification ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 45px;
  left: 0px;
  width: 350px;
  background-color: #fff;
  font-weight: bold;
  position: absolute;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown_notification li {
  border-bottom: 1px solid #e5e5e5;
  padding: 4px;
  padding-inline: 12px;
}
.dropdown_notification a li {
  color: #596377;
  text-decoration: none;
}
.dropdown_notification li:last-child {
  /*border-bottom: none;*/
}
.dropdown_notification li:hover {
  background-color: #e5e5e5;
  color: #596377;
}
.dropdown_notification .button {
  width: 100%;
  height: 18px;
  padding: 8px;
  padding-right: 15px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
}
.dropdown_notification .button:hover {
  color: rgb(199, 201, 202);
}
.dropdown_notification .button:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 5px solid;
  border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
  right: 3px;
  top: 20px;
}
.dropdown_notification .nav-link {
  color: #596377;
  padding: 0.1rem 1rem;
}
.dropdown-head {
  display: flex;
  justify-content: space-between;
  padding-inline: 10px;
}
.dropdown-title {
  align-items: center;
  display: flex;
}

.dropdown-title span {
  font-size: 25px;
  font-weight: bold;
}
.dropdown-divider {
  height: 0;
  margin: 0rem;
  /*0.5rem 0;*/
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.notification-count {
  color: #596377;
  padding: 8px;
  /*padding-right: 15px;*/
}

.notification-img-circle {
  border-radius: 25px;
}

.notification-media-body {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 0.1rem !important;
  width: 80%;
}
.notification-title {
  width: 100%;
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
}


.notification-meta {
  display: flex;
  /* display: inline-flex; */
  justify-content: space-between;
  
}

.notification-detail{
  /* display: inline-flex; */
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
}

.notification-list-badget {
  /* position: relative; */
  display: flex;
  height: 18px;
  width: 18px;
  /* background-color: #4cd137; */
  background-color: #1db7e2;
  border-radius: 50%;
  /* bottom: 0;
  right: 0; */
  color: #fff;
  font-size: small;
  justify-content: center;
  align-items: center;
  /* border: 3px solid white */
}
/*
background-color:#ff3232 ;
    
*/
