.upload {
  display: none;
}
.icon-camera {
  right: -100px;
  position: relative;
  bottom: 40px;
}
.mg-10 {
  margin: 10px;
}
.profile-flag {
  height: 25px;
}

.profile-between {
  justify-content: space-between;
  display: flex;
  margin-right: 40px;
}

.profile-row {
  flex-direction: row;
  display: flex;
}
