.container-howtowork {
  padding-top: 70px;
  padding-bottom: 70px;

}
.container-howtowork-title {
  text-align: center;
}
.container-howtowork-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.howtowork-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}


