/*.chat {
  margin-top: auto;
  margin-bottom: auto;
}*/
.card {
  /*height: 500px;*/
  border-radius: 15px !important;
  background-color: white !important;
}
.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}

.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.container {
  align-content: center;
}
.search {
  border-radius: 15px 0 0 15px !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 0px !important;
  border-bottom: 1px dotted #b3a9a9;
  display: flex;
}
.contacts li:hover {
  cursor: pointer;
  background-color: #e5e5e5;
  /*  color: #596377;*/
}
.active {
}
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 72px;
  width: 72px;
  border: darkgrey;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: xx-large;
  color:#495057
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.online_icon {
  position: absolute;
  height: 18px;
  width: 18px;
  /* background-color: #4cd137; */
  background-color: #1db7e2;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  border: 3px solid white
}
.offline {
  background-color: #c23616 !important;
}
.contact_name {
  margin-left: 15px;
  /*width: -moz-available;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  width: auto;
  align-items: center;
    display: flex;
}
.contact_name span {
  font-size: 15px;
  color: #5b5c5d;
  font-weight: 600;
}
.contact_name p {
  font-size: 10px;
  color: #4e4e50;
}
.contact_name small{
        display: block;
        text-decoration: none;
        font-size: 12px;
        color: #4e4e50;
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  position: relative;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: #495057;
  font-size: 10px;
}
.msg_date {
  color: #958f8f;
  font-size: 12px;
  justify-content: center;
  display: flex;
}

.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: #495057;
  font-size: 10px;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;

  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.contact_profile {
  align-items: center;
  display: flex;
}
.contact_profile span {
  position: absolute;
  right: 20px;
}
.contacts_card {
  top: 10px;
}
.contact_badge {
  align-items: center;
  display: flex;
}
.contact_badge span {
  display: inline-block;
  padding: 0.4em 0.6em;
  margin-left: 4px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.7rem;
  color: #ffffff;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #1db7e2;
  position: absolute;
  right: 70px;
}

@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}

/*
.msg_card_body {
  overflow-y: auto;
}
*/
