.nav-link .active {
  font-weight: bold;
}
.card .nav .nav-link {
  color: #4c4c4c;
}
.card .nav .nav-link .small {
  color: #bbbbbb;
}

.card .nav .nav-link:hover {
  color: #4c4c4c;
  text-decoration-line: none;
  text-decoration: none;
  background-color: #e5e5e5;
}
.container-profile-menu {
  padding: 70px 0;
  padding-bottom: 20px;
}
