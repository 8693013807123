.admenu-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 70px;
}

/*
.card {
  padding: 20px;
}
*/
