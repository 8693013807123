.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1050;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  outline: 0;
}
.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal_container {
  position: absolute;
  top: 10rem;
  background-color: #ffffff;
  padding: 1rem;
  /* width: 400px; */
  border-radius: 10px;
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal_close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 15;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 20px;
}
.modal-img {
  height: 550px;
  width: 400px;
}

.modal-scroll {
  overflow: auto;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 350px);
}

.img-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.img-respon {
  height: 200px;
  width: 100%;
}
.modal-title {
}

.modal-header {
  justify-content: center;
}

@media screen and (max-width: 480px) {
  /*PhoneBig*/
  .modal-content {
    width: calc(100vw - 15px);
  }
}
@media screen and (min-width: 481px) {
  /*PhoneBig*/
  .modal-content {
    width: 470px;
  }
}

.modal-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-loading-content {
  position: absolute;
  top: calc(100vh / 2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;

  border-radius: 0.3rem;
  outline: 0;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font: bold;
  font-size: 24px;
  text-align: center;
}
/*
background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: none;
      background-color: rgba(0, 0, 0, 0.5);
       position: relative;
  */
