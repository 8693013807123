.goder-body {
  margin-top: 10px;
  margin-inline: 17px;
  margin-bottom: 17px;
}

.goder-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
}

.goder-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.goder-shadow:hover{
      box-shadow: -1px 5px 14px 0px #15111147;
}

.goder-img-list{
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 275px;
}

.goder-img-list-panel{
  object-fit: cover;
    display: block;
    max-width: 100%;
    height: 175px;
}

.goder-link-list{
  text-decoration: none;
  color: #094683;
  font-size: large;
  font-weight: 300;
  text-transform: uppercase;
}

/* @media screen and (max-width: 769px) {
  .goder-link-list {
      font-size: larger;
    }
} */

