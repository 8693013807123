@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Anton");

@import url("https://fonts.googleapis.com/css?family=Baloo+Paaji&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f4f4f7;
  font-size: 16px;
}

h1 {
  font-family: "Baloo Paaji", cursive;
}

h2 {
  font-family: "Baloo Paaji", cursive;
}

h3 {
  font-family: "Baloo Paaji", cursive;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  padding: 0.375rem 2rem;
}

.btn.btn-primary {
  color: #fff;
  background-color: #1db7e2;
  border-color: #1db7e2;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1db7e2;
  border-color: #1db7e2;
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #1db7e2;
  border-color: #1db7e2;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 205, 64, 0.5);
}

.btn.btn-danger {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}

.btn.btn-facebook {
  color: #fff;
  background-color: #4267b2;
  border-color: #4267b2;
}

.btn.btn-facebook:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #4267b2;
  border-color: #4267b2;
}

.btn.btn-facebook:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4267b2;
  border-color: #4267b2;
}

.btn.btn-facebook:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}

.card {
  padding: 10px;
}
.card-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (min-width: 1300px) {
  .navbar-brand-big {
    display: block;
  }
  .navbar-brand-small {
    display: none;
  }
  .navbar-center {
    width: calc(100vw * 0.4);
  }
  .navbar-items {
    display: -webkit-flex;
    display: flex;
  }
  .navbar-mobile {
    display: none;
  }
  .container-legal {
    padding-top: 100px;
    padding-left: 20px;
  }
  .Timeline-ColLeft {
    padding-left: 100px;
  }
  .Timeline-ColRight {
    padding-right: 100px;
    padding-left: 50px;
  }
  .container-download {
    display: none;
  }
}
@media screen and (max-width: 1550px) {
  .Timeline-ColLeft {
    padding-left: 10px;
  }
  .Timeline-ColRight {
    padding-right: 10px;
    padding-left: 50px;
  }
  .container-download {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .navbar-brand-big {
    display: block;
  }
  .navbar-brand-small {
    display: none;
  }
  .navbar-center {
    width: calc(100vw * 0.3);
    padding-left: 20px;
  }
  .navbar-mobile {
    display: none;
  }
  .navbar-items {
    display: -webkit-flex;
    display: flex;
  }
  .container-legal {
    padding-top: 100px;
    padding-left: 20px;
  }
  .Timeline-ColLeft {
    padding-left: 10px;
  }
  .Timeline-ColRight {
    padding-left: 10px;
  }
  .container-download {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-brand-big {
    display: none;
  }
  .navbar-brand-small {
    display: block;
  }

  .navbar-items {
    display: -webkit-flex;
    display: flex;
  }
  .navbar-mobile {
    display: none;
  }
  .container-legal {
    padding-top: 100px;
    padding-left: 20px;
  }
  .container-download {
    display: block;
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  /*IPAD*/
  .navbar-items {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-center {
    width: 70%;
  }
  .container-legal {
    padding-top: 100px;
    padding-left: 20px;
  }
  .container-download {
    display: block;
    display: flex;
  }

}
@media screen and (max-width: 480px) {
  /*PhoneBig*/
  .navbar-center {
    width: 65%;
  }
}
@media screen and (max-width: 320px) {
  /*PhoneSmall*/
  .navbar-center {
    width: 50%;
  }
}

/*  flex-wrap:wrap;
body {
    font-family: "Roboto", sans-serif;
    background-color: #f4f4f7;
    font-size: 12px;
  }*/
