.wall-left {
  position: sticky;
  top: 0;
  padding-top: 70px;
}

.wall-right {
  position: sticky;
  top: 0;
  padding-top: 70px;
}
.wall-center {
  margin-top: 90px;
}
