.progress-bar {
  position: relative;
  height: 15px;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #333;
}
.filler {
  background: #1da598;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}
.none-decoration a {
  text-decoration: none;
}
