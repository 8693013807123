@media screen and (min-width: 770px) {
  .container-galery {
    display: flex;
    flex-direction: row;
  }
  .goder-image-prev {
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  .thumbnails .goder-image-thumbnails {
    height: 60px;
    padding: 3px;
  }

  .goder-image-show {
    overflow: hidden;
    width: 100%;
    justify-content: center;
    display: flex
  }
  .goder-image {
    /* height: 500;
    width: 750; */
    object-fit: contain;
    max-height: 500px;
    max-width: 750px;
  }
}

@media screen and (max-width: 769px) {
  .container-galery {
    display: flex;
    flex-direction: column;
  }
  .goder-image-prev {
    margin: 10px;
    display: flex;
    flex-direction: row;
  }
  .thumbnails .goder-image-thumbnails {
    min-height: 40px;
    max-height: 60px;
    padding: 3px;
  }

  .goder-image {
    max-height: 500px;
    display: block;
    max-width: 100%;
  }

  .goder-image-show {
    overflow: hidden;
    width: 100%;
    justify-content: center;
    display: flex
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.goder-image:hover {
  cursor: zoom-in;/*zoom-out*/
}
