.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 45px;
  left: 0px;
  width: 200px;
  background-color: #fff;
  font-weight: bold;
  position: absolute;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown li {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
}
.dropdown a li {
  color: #596377;
  text-decoration: none;
}
.dropdown li:last-child {
  border-bottom: none;
}
.dropdown li:hover {
  background-color: #e5e5e5;
  color: #596377;
}
.dropdown .button {
  width: 100%;
  padding: 8px;
  padding-right: 15px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.5);
}
.dropdown .button:hover {
  color: rgb(199, 201, 202);
}
.dropdown .button:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 5px solid;
  border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
  right: 3px;
  top: 20px;
}
.dropdown .nav-link {
  color: #596377;
  padding: 0.1rem 1rem;
}


/*
background-color:#ff3232 ;
    
*/
