.container-countrie {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  background-repeat: no-repeat;

  color: #6f7c82;
  font-style: normal;
  overflow-x: hidden;
  padding: 0;
  justify-content: center;
  min-height: calc(100vh - 65px);
}
.countrie-list {
  margin: 0 auto;
  min-width: 480px;
  padding-top: 100px;
  height: calc(100vh - 65px);
}
.countrie-list ul {
  list-style: none;
}

.countries-scroll {
  overflow-y: auto;
  height: calc(100vh - 270px);
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
}
.countrie-list ul li a {
  color: #6f7c82;
}

.countrie-list ul li:hover {
  cursor: pointer;
  background-color: #e5e5e5;
  color: #596377;
}
.country-img {
  height: 25px;
  width: 30px;
}
