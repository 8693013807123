.link-title:hover {
  text-decoration: none;
}
.faq-status-pending {
  color: black;
  position: relative;
  top: -25px;

  background-color: yellow;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0;
  padding-bottom: 0;
}

.faq-status-closed {
  color: white;
  position: relative;
  top: -25px;

  background-color: grey;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0;
  padding-bottom: 0;
}

.faq-img {
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 150px;
}