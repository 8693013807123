.profile_left {
  position: sticky;
  top: 0;
}

.profile_right {
  position: sticky;
  top: 0;
}

.profile_center {
  padding: 70px 0;
}
