.navbar {
  width: 100%;
  padding: 0.5rem 1;

  background-color: #343a40 !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0rem 0.8rem;
}

.navbar_brand:hover {
  color: #ffffff;
  text-decoration: none;
}

.badge {
  display: inline-block;
  padding: 0.4em 0.6em;
  margin-left: 4px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.7rem;
  color: #ffffff;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #1db7e2;
}
/*
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    .navbar_brand-logo {
  margin-right: 0.5rem;
}
    left: 0;
    z-index: 1030;
 }*/
.navbar-items {
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items: center;
  margin-left: auto !important;
}
.navbar-items .nav-item {
  width: auto;
}

.navbar-items .nav-item .nav-link {
  display: block;
  padding: 0.1rem 0rem;
  color: rgba(255, 255, 255, 0.5);
}

.navbar-center {
  margin-top: 10px;
  padding-left: 20px;
}

.navbar-mobile {
  top: -2px;
  position: relative;
}

/*
  background-color: #1c3643;
  flex-wrap: wrap;
*/

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
}

.suggestions {
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 250px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(400px + 1rem);
  position: absolute;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #e5e5e5;
  color: #596377;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
