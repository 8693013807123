.avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  /*background-color: #004387;*/
      border-style: groove;
}

.avatar.small {
  width: 40px;
  height: 40px;
}

.avatar.medium {
  width: 60px;
  height: 60px;
}

.avatar.big {
  width: 80px;
  height: 80px;
}

.avatar span {
  font-family: var(--font-primary);
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.avatar.medium span {
  font-size: 22px;
}
.avatar.big span {
  font-size: 30px;
}

.avatar-item {
  display: flex;
  margin-block: 8px;
  justify-content: space-between;
}
.avatar-name {
  display: flex;
  align-items: center;
  margin-inline: 10px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: bold;
}
.avatar-text {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.avatar-detail {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

}
.avatar-head {
  display: flex;
  flex-direction: row;
}
.avatar-date {
 
  font-size: 12px;
  color: #aaa;
  font-family: Arial, serif;
  margin-inline: 10px;
}
.bidder{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 7px;
}
       
/*var(--mainBrandColor);
white-space:nowrap; no se de enter el texto , que en la misma mlinea
*/
