.Login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login form {
  margin: 0 auto;
  min-width: 320px;
}

.Login button {
  width: 100%;
}
@media all and (min-width: 480px) {
}
