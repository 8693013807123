.marketstore-cover {
  padding: 10px;
}
.marketstore-cover .cover {
  height: 300px;
  width: 100%;
}
.marketstore-description {
  position: relative;
  display: flex;
  justify-content: center;
  top: 240px;
  background: transparent;
  font-size: 22px;
  text-align: center;
}

.marketstore-title {
  position: relative;
  display: flex;
  justify-content: center;
  top: 240px;
  background: transparent;
  font-size: 22px;
  text-align: center;
}
.container-marketstore-slider {
  padding: 0px;
  margin: 0px;
  top: -50px;
  position: relative;
}

.marketstore-marketstore {
  position: relative;
  text-align: center;
  top: 40px;
}
