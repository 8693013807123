.panel-left {
  position: sticky;
  top: 0;
}

.panel-right {
  position: sticky;
  top: 0;
}
.panel-center {
  margin-top: 70px;
}
