.register {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.register form {
  margin: 0 auto;
  max-width: 320px;
}

.register button {
  width: 100%;
}
