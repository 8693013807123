.search-left {
  position: sticky;
  top: 0;
  padding-top: 70px;
}

.search-right {
  padding-top: 100px;
  min-height: 100vh;
}
