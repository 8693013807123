.container-rating {
  text-align: center;
}
.container-rating .btn {
  font-size: 1.5rem;
}
.container-rating .btn-outline-light {
  color: black;
  border-color: transparent;
}
