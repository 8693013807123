.suscription_head_year {
  text-align: center;
  background: #004287;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suscription_head_month {
  text-align: center;
  background: #0dbadf;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.suscription_footer {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.suscription_head_plan {
  text-align: center;
  background: #707881;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suscription_body {
  font-size: 15px;
  background: white;
}
.suscription_item {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
