.notification-left {
  position: sticky;
  top: 0;
  padding-top: 70px;
}

.notification-right {
  position: sticky;
  top: 0;
  padding-top: 70px;
}
.notification-center {
  margin-top: 80px;
}
