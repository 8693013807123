.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    outline: 0;
    justify-content: center;
}

/* .modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;
    background-color:transparent;

} */



/* .modal_container { */
    /* position: absolute; */
    /* top: 10rem; */
    /* background-color: #ffffff; */
    /* padding: 1rem; */
    /* width: 400px; */
    /* border-radius: 10px; */
    /* display: flex; */
    /* z-index: 2; */
    /* flex-direction: column; */
    /* justify-content: center;
    align-items: center;
} */

.modal_close_zoom-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #fff;
    padding: 10px;
    /* background-color: #1db7e245; */
    font-size: 30px;
    /* border-radius: 20px; */
}

.modal-img {

    max-height: 100vh;
    height: auto;
    width: auto;
    max-width: 90%;
    display: block;


}

.modal-scroll {
    overflow: auto;
    width: 100%;
    height: auto;
    max-height: calc(100vh - 350px);
}

.img-center {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* height: 100%; */
}

.img-respon {
    height: auto;
    width: 90%;
}


.modal-header {
    justify-content: center;
}

.modal-img:hover {
    cursor: zoom-out;
    /*zoom-out*/
}
@media screen and (max-width: 480px) {

    /*PhoneBig*/
    .modal-content-zoom {
        width: 100vh;
    }
}

@media screen and (min-width: 481px) {

    /*PhoneBig*/
    .modal-content-zoom {
        width: 100vh;
    }
}

@media screen and (min-width: 576px){
    .modal-dialog-zoom {
            max-width: 100vh;
            margin: 1.75rem auto;
        }
}

.modal-loading-zoom{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-loading-content-zoom {
    position: absolute;
    /* top: calc(100vh / 2); */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    pointer-events: auto;

    /* border-radius: 0.3rem; */
    outline: 0;
    align-items: center;
    justify-content: center;
    /* color: #ffffff; */
    /* font: bold; */
    /* font-size: 24px; */
    text-align: center;
}