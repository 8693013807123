.img_market_banner{
    padding-inline: 150px;
    padding-top: 70px;
    padding-bottom: 70px;
}
.container_market_banner{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 30px;
    padding-right: 240px
}

@media screen and (max-width: 1200px) {

    .img_market_banner {
        display: none;
    }

    .container_market_banner{

         text-align: center;
        padding-right: 0px
    }

}