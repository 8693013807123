@media all and (min-width: 480px) {
  .container-panel-menu {
    padding: 70px 0;
  }
}
.container-panel-menu .card .nav .nav-link {
  color: #4c4c4c;
  padding: 0.1rem 1rem;
}
.container-panel-menu .card .nav .nav-link .small {
  color: #bbbbbb;
}

.container-panel-menu .card .nav .nav-link:hover {
  background-color: #e5e5e5;
  color: #596377;
  text-decoration-line: none;
  text-decoration: none;
}
