.title-goder-relation {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center !important;
  text-transform: uppercase;
}
.goder-relation-item {
  text-align: center;
}

.goder-relation-item a:hover {
  text-decoration: none;
}

.goder-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.goder-img-relation {
  object-fit: cover;
  width: 175px;
  height: 175px;
  display: block;
  max-width: 100%;
}

.goder-link {
  text-decoration: none;
  color: #094683;
  font-size: larger;
  font-weight: 300;
  text-transform: uppercase;
}