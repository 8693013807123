.container-btn-title {
  display: inline-flex;
  display: flex;
  justify-content: space-between;
}
.panel-title-left {
  text-align: start;
  padding: 5px;
}

.panel-button-right {
  text-align: end;
  padding: 5px;
}
