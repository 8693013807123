.serverseen_line {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.chat_check .point {
  display: block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #bbbdc3;
  margin: 2px;
}

.chat_check .point .check_success {
  background-color: #55a1e1;
  animation: none;
}

.check_failed {
  background-color: #e15555;
  animation: none;
}
