.title a {
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} 

.transaction-img{
  object-fit: cover;
  display: block;
  max-width: 100%;
  height: 150px;
}
